<!-- 角色编辑 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'编辑角色':'新增角色'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px">
      <!--<el-form-item label="机构类型:" prop="organization_category">-->
      <!--  <el-select-->
      <!--    clearable-->
      <!--    v-model="form.organization_category"-->
      <!--    placeholder="请选择"-->
      <!--    class="ele-fluid">-->
      <!--    <el-option-->
      <!--      v-for="(item) in dict.type.organization_type"-->
      <!--      :label="item.label"-->
      <!--      :value="item.value"-->
      <!--    />-->
      <!--  </el-select>-->
      <!--</el-form-item>-->
      <el-form-item label="角色名称:" prop="name">
        <el-input
            clearable
            v-model="form.name"
            placeholder="请输入角色名称"/>
      </el-form-item>
      <!--<el-form-item label="角色编码:" prop="coding">-->
      <!--  <el-input-->
      <!--      clearable-->
      <!--      v-model="form.coding"-->
      <!--      placeholder="请输入角色编码"/>-->
      <!--</el-form-item>-->
      <el-form-item label="备注:">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="form.notes">
        </el-input>
      </el-form-item>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {Addroles, Editroles} from "@/api/humanResources";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        // organization_category:[
        //   { required: true, message: '请选择机构类型', trigger: 'change' },
        // ],
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        // coding: [
        //   { required: true, message: '请输入编码', trigger: 'blur' },
        // ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addroles(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editroles(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
